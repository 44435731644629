import './App.css';
import { ConstantComponent } from './ConstatantScreen/ConstantScreen';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router> 
      <ConstantComponent.Dashboard />
      
      </Router>
    </div>
  );
}

export default App;
