import Header from "../Components/Header/Header";
import Dashboard from "../Screens/Dashboard/Dashboard";
import Home from "../Screens/Home/Home";
import Footer from "../Components/Footer/Footer";
import Institutes from "../Screens/Institutes/Institutes";
import AboutUs from "../Screens/AboutUs/AboutUs";


export const ConstantComponent = {
Home,
Dashboard,
Header,
Footer,
Institutes,
AboutUs
}