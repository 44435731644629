// src/components/AboutUs.js
import React from 'react';
import './AboutUs.css'; // We'll create this CSS file next

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <section className="about-us-header">
        <h1>About Us</h1>
        <p>Discover Top Talent or Land Your Dream Job - Campus Placement Has You Covered!</p>
      </section>
      
      <section className="who-we-are">
        <h2>Who We Are</h2>
        <p>
          At Campus Placement, we embarked on a journey fueled by a collective foundation
          with the outdated and cumbersome recruitment processes. We envisioned a
          platform that would simplify hiring for everyone involved - recruiters, applicants, and MSMEs.
          With this goal in mind, we set out to create a user-friendly interface that would
          assist, motivate, and welcome a talent-discovery match that would inspire.
        </p>
        <p>
          Through relentless dedication and innovative thinking, we brought our ideas to
          fruition. Campus Placement emerged as a beacon of clarity and convenience in the recruitment
          landscape. Our algorithm-driven system facilitates seamless interactions between
          candidates and recruiters, making hiring less rigorous and more aligned with the
          specific talents hiring companies fit for any role or team that have been made to
          ensure scalability.
        </p>
        <p>
          Today, Campus Placement stands as a leader, empowering companies to take their hiring
          process to new heights. Our commitment to transformation is driven by the belief
          that everyone deserves to find their ideal match in the professional world.
        </p>
      </section>
      
      <section className="our-values">
        <div className="value">
          <h3>Recruitment company work with effectiveness.</h3>
          <p>
            Independent with internal resources or Manpower agencies enables recruiting
            companies to stay focused on managing and meeting their business needs. Early
            stage and mid-sized businesses are eager to implement well-organized
            management solutions or hiring platforms.
          </p>
        </div>
        <div className="vision">
          <h3>Our Vision</h3>
          <p>
            To create a community focused on empowering individuals, making the recruitment
            of the right candidates, and improving the process of recruiting easier.
          </p>
        </div>
        <div className="mission">
          <h3>Our Mission</h3>
          <p>
            At Campus Placement, we are committed to transforming outdated and cumbersome recruitment
            processes. We provide a platform that helps everyone find a talent match for their
            careers and their lives and contributes to our world.
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
