const httpPrefix = "http";
const httpPrefixs = "http";

export const ApiProfile = {
    
    Dev: `${httpPrefix}://52.66.72.6:8080/campus-placement-dev/api/v1/`, 
    Test: `${httpPrefix}://52.66.72.6:8080/campus-placement-int/api/v1/`,
    UAT:  `${httpPrefix}://52.66.72.6:8080/campus-placement-uat/api/v1/`,
    Prod: `${httpPrefixs}://admin.campusplacement.in/campus-placement/api/v1/`
}

export const AdminUrls = {
    Dev: `${httpPrefix}://52.66.72.6:8080/campus_int_admin/#/login`, 
    Test: `${httpPrefix}://52.66.72.6:8080/campus_int_admin/#/login`,
    UAT: `${httpPrefix}://52.66.72.6:8080/campus_uat_admin/#/login`,
    Prod: `${httpPrefixs}://admin.campusplacement.in/`
}
export const StudentUrls = {
    Dev: `${httpPrefix}://52.66.72.6:8080/campus_dev_student/#/login`, 
    Test: `${httpPrefix}://52.66.72.6:8080/campus_int_student/#/login`,
    UAT: `${httpPrefix}://52.66.72.6:8080/campus_uat_student/#/login`,
    Prod: `${httpPrefixs}://student.campusplacement.in/`

}
export const getActivProfile = (profile) => {
    switch (profile) {
        case 'Dev': return (ApiProfile.Dev);
        case 'Test': return (ApiProfile.Test);
        case 'UAT': return (ApiProfile.UAT);
        case 'Prod': return (ApiProfile.Prod);
        default: return (ApiProfile.Dev);
    }
}