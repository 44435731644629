import React, { useState } from 'react'
import quikHireLogo from '../../Assets/quikHireLogo.png'
import './Header.css'
import Modal from "react-modal";
import instituteIcon from '../../Assets/InstituteIcon.svg'
import studentIcon from '../../Assets/StudentIcon.svg'
import { useNavigate } from 'react-router-dom';
import { campusAdminUrls, campusStudentUrls } from '../../Constants/ApiUrlConstants';

const Header = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleLogin = () =>{
    setModalOpen(true)
  }
  const handleCloseModal = () =>{
    setModalOpen(false)
  }
  const handleInstituteLogin = () => {
    window.open(campusAdminUrls, '_blank');
  };
  const handleStudentLogin = () => {
    window.open(campusStudentUrls, '_blank');
  };
  const navigate = useNavigate()
  const navigateToModule = (path) =>{
    navigate(`/${path}`)
  }
  return (
    <div className='headerMainDiv'>
      <div className="logoImage">
        <img src={quikHireLogo} alt=''/>
        <small className='poweredBy'>Powered by QuikHire</small>
      </div>
      <div className="menuMainDiv">
        <ul className='nav-Items'>
          {/* <li className='nav-Item'>Home</li> */}
          <li className='nav-Item' onClick={()=>{navigateToModule('home')}}>Home</li>
          <li className='nav-Item' onClick={()=>{navigateToModule('institutes')}}>Institute</li>
          <li className='nav-Item' onClick={()=>{navigateToModule('aboutUs')}}>About Us</li>
          <li className='nav-Item'>Contact Us</li>
        </ul>
      </div>
      <div className="userdiv">
        <button className='longinBtn' onClick={()=>{handleLogin()}}>Login</button>
      </div>
      <Modal
  ariaHideApp={false}
  isOpen={modalOpen}
  onRequestClose={handleCloseModal}
  overlayClassName={'modalOverlayStyle'}
  className={`modalStyle ModalCss`} 
>
  <div className="modalHeader">
    <h2>Select One</h2>
    <button onClick={handleCloseModal} className="Close_Button">×</button>
  </div>
  <div className="modalBody"> 
    <div className="instituteDiv" onClick={()=>{handleInstituteLogin()}}>
      <img src={instituteIcon} alt="" />
      <h4>Education Institute</h4>
    </div>
    <div className="instituteDiv studentDiv" onClick={()=>{handleStudentLogin()}}>
      <img src={studentIcon} alt="" />
      <h4>Student</h4>
    </div>
  </div>
  {/* <div className="modalFooter">
    <button onClick={handleCloseModal}>Close</button>
    <button onClick={()=>{}}>Save Changes</button>
  </div> */}
</Modal>

    </div>
  )
}

export default Header