import React from 'react';
import './Footer.css';

import quikHireLogo from '../../Assets/quikHireLogo.png'
import { useNavigate } from 'react-router-dom';
const Footer = () => {
    const navigate = useNavigate()
    const navigateToModule = (path) =>{
      navigate(`/${path}`)
    }
    return (
        <section className="footer_sec">
            <div className="container">
                <div className="footer1">
                    <div className="footer-item">
                        <div className="logo"> 
                                <img src={quikHireLogo} width="200px" alt="QuikHire Logo" /> 
                        </div>
                        <div className="content">
                            <h5>Find your dream job on our AI-powered platform. Get personalized recommendations, connect with recruiters, and simplify your job search. Join now!</h5>
                        </div>
                        <div className="social-icons">
                            <ul>
                                <li><a rel="noopener noreferrer" href="#"><i className="bi bi-facebook"></i></a></li>
                                <li><a rel="noopener noreferrer" href="#"><i className="bi bi-instagram"></i></a></li>
                                <li><a rel="noopener noreferrer" href="#"><i className="bi bi-twitter"></i></a></li>
                                <li><a rel="noopener noreferrer" href="#"><i className="bi bi-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    {/* <div className="footer-item footerMenu">
                        <div className="categ">
                            <h4 className='compH4'>Company</h4>
                            <ul>
                                <li>About Us</li>
                                <li>FAQ's</li>
                                <li>Delete Account</li>
                                <li>Terms of Use</li>
                                <li>Privacy Policy</li>
                                <li>Channel Partner</li>
                                <li>Affiliate</li>
                            </ul>
                        </div>
                    </div> */}

                    <div className="footer-item">
                        <div className="newsletter categ">
                            <h4>Sign up for our Newsletter here!</h4>
                            <div id="newsletter">
                                <form action="#" method="post">
                                    <input type="text" name="fullname" placeholder="Name" required />
                                    <input type="email" name="emailid" placeholder="Email" required />
                                    <button type="submit" name="submit" className="news-btn">
                                        Sign up
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="footer-item">
                        <div className="categ">
                            <h4>Our Address</h4>
                            <p>
                                Plot No 125 &amp;126, ARKA Heights, 4th Floor, <br />
                                Lumbini Avenue, Gachibowli, <br />
                                Hyderabad, Telangana 500032<br />
                            </p>
                            <p><a href="mailto:info@campusplacement.in"><i className="fa fa-envelope"></i> info@campusplacement.in</a></p>
                            <p><a href="tel:7997995077"><i className="fa fa-phone"></i> 7997995077</a></p>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="copy">
                        <h5>Copyright © 2024 Campus Placement, All rights reserved.</h5>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
